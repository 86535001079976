import React from "react"
import "../assets/scss/screen.scss"
import Header from "./Header"
import Footer from "./Footer"
import NewsletterSubscription from "./NewsletterSubscription"
import { graphql, useStaticQuery } from "gatsby"

const Layout = ({ children }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          stickyNav
        }
      }
    }
  `)
  const { stickyNav } = site.siteMetadata
  return (
    <div className="site-wrap" data-nav={stickyNav ? "sticky" : ""}>
      <Header />
      {children}
      <NewsletterSubscription />
      <Footer />
    </div>
  )
}

export default Layout
